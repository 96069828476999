import {Injectable, NgZone} from '@angular/core';
import {ActionPerformed, PushNotifications, PushNotificationSchema, Token} from "@capacitor/push-notifications";
import {Device} from "@capacitor/device";
import {SecurityService} from "@cdq/api";
import {Platform, ToastController} from "@ionic/angular";
import {NavigationStart, Router} from "@angular/router";
import {Preferences} from "@capacitor/preferences";
import {App} from '@capacitor/app';
import {
  Channel,
  LocalNotifications,
  ActionPerformed as LocalActionPerformed
} from "@capacitor/local-notifications";
import {LocalNotificationSchema} from "@capacitor/local-notifications/dist/esm/definitions";
import {AlertService} from "../alert/alert.service";
import {CDQCAPPlugin} from "../../capacitor/cdq/cdq";
import {gitVersion} from "../../../environments/git-version";
import {EnvService} from "../environment/env.service";
import {Subscription} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  // localNotification = ['notif 1', 'notif 2', 'notif 3']

  private fcmToken: string;

  private notifId = 1;

  private androidChannelId: string;

  public tapOpened = false;
  public tapUrl = '';

  localNotification = {
    image: '',
    title: '',
    body: ''
  }

  listOfNotifications: any[] = []

  empty = false
  count = 0

  private navigationSubscription: Subscription;
  private isNotificationNavigation = false;

  constructor(private securityService: SecurityService,
              public platform: Platform,
              private ngZone: NgZone,
              private toastCtrl: ToastController,
              private alertSvc: AlertService,
              private router: Router,
              private envService: EnvService) {
    // this.checkNotificationPermissions();

    if (this.platform.platforms().includes('android')) {
      this.initNotifications();
    }

    // Listen for navigation events
    this.navigationSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && this.tapOpened) {
        this.isNotificationNavigation = true;
        if(this.tapOpened && !event.url.startsWith('/client-offer')){
          console.log('check routing path in ns service. currently opening offer, shoud cancel')
        }
      }
    });
  }

  checkNotification(): boolean {
    if (this.listOfNotifications.length > 0) {
      this.empty = false
    } else {
      this.empty = true
    }
    return this.empty
  }

  countNotification(): number {
    return this.count = this.listOfNotifications.length
  }

  public currentPushToken(){
    return this.fcmToken;
  }

  storeNotification(notification: any): void {
    this.localNotification.title = notification.notification.title
    this.localNotification.body = notification.notification.body
    this.localNotification.image = notification.notification.image
    this.listOfNotifications.push(this.localNotification)
  }

  listNotification(): any {
    return this.listOfNotifications
  }

  clearNotification(notification: any): void {
    this.listOfNotifications.forEach(r => {
      if (notification == r) {
        this.listOfNotifications = this.listOfNotifications.filter((value) => value != notification);
      }
    })
  }

  clearAll(): any {
    this.listOfNotifications = []
    return this.localNotification
  }

  initNotifications() {
    //handle first open
    console.log('[notification] initNotif')
    this.updateDeviceToken();
    if (this.platform.platforms().includes("mobile") && !this.platform.platforms().includes("mobileweb")) {
      PushNotifications.addListener("pushNotificationReceived", async (notification: PushNotificationSchema) => {
        console.log("[CDQ Notification Handler] Push received: ", notification);
        const data = notification.data
        //this.storeNotification(notification.body)
        this.forwardNotification(notification);
      });
      // Method called when tapping on a notification
      PushNotifications.addListener("pushNotificationActionPerformed", async (notification: ActionPerformed) => {
        console.log("[CDQ Notification Handler] Push Action Performed: ", notification);
        //Exemple : Redirect to offerId
        this.ngZone.run(() => {
          this.loadOfferFromTap(notification);
        })
      });

      console.log('push notification requested')
      const me = this;
      PushNotifications.addListener("registration", (token: Token) => {
        this.fcmToken = token.value;
        Preferences.set({key: "fcmToken", value: token.value}).then(() => {
            console.log("fcm push token received", token);
          }, (error) => console.error("error while storing fcm token", error)
          //record device
        );
        this.updateDeviceToken();
      });

      PushNotifications.addListener("registrationError", (error: any) => {
        console.log("Error on registration: " + JSON.stringify(error));
      });

      PushNotifications.register();
      // PushNotifications.requestPermissions().then(result => {
      //   if (result.receive === 'granted') {
      //     // Register with Apple / Google to receive push via APNS/FCM
      //     PushNotifications.register();
      //     console.log('notifications authorized')
      //   } else {
      //     console.log('error while requestion permission', result)
      //   }
      // });

      LocalNotifications.registerActionTypes({
        types: [{
          id: 'offerPush',
          actions: [{
            id: 'open',
            title: 'Voir l\'Offre',
            input: false,
            foreground: true
          }, {
            id: 'offerParticipation',
            title: 'Participer',
            input: false,
            foreground: true
          }]
        }]
      })

      if (this.platform.platforms().includes("android") && this.platform.platforms().includes("mobile") && !this.platform.platforms().includes("mobileweb")) {
        const notificationChannel: Channel = {
          id: 'cdq-android-notifications',// id must match android/app/src/main/res/values/strings.xml's default_notification_channel_id
          name: 'Oui Love Locals',
          description: '',
          importance: 4,
          visibility: 1
        };
        LocalNotifications.createChannel(notificationChannel);
      }
      LocalNotifications.addListener("localNotificationActionPerformed", async (notification: LocalActionPerformed) => {
        console.log("[CDQ Notification Handler] Local Action Performed: ", notification);
        //Exemple : Redirect to offerId
        this.ngZone.run(() => {
          console.log('[notification] action performed on notification:', notification.actionId)

          // try to cancel any other navigation in progress

          this.loadOfferFromTap(notification);
        })
      });
    }
  }

  checkNotificationPermissions(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      PushNotifications.checkPermissions().then((value) => {
        if (value.receive == 'granted') {
          resolve(true);
        } else if (value.receive == 'prompt' || value.receive == 'denied') {
          resolve(false);
        }
      })
    })

  }

  loadOfferFromTap<Type extends LocalActionPerformed | ActionPerformed>(notification: Type) {
    this.alertSvc.load();
    try {
      //get offer id
      //@tsi
      const notificationData = (notification.notification as LocalNotificationSchema)['data'];
      console.log('[notification] on open action ', notificationData)
      if (notification.actionId == 'offerParticipation' || notification.actionId == 'PARTICIPATE_OFFER') {
        ////get offer data and participate
        const offerId = notificationData.offerId
        console.log('opening offer ' + offerId + " from notification");
      } else if (!notification.actionId || notification.actionId == 'tap' || notification.actionId == 'open' || notification.actionId == 'offerParticipation'
      || notification.actionId == 'OPEN_OFFER') {
        ////get offer data and navigate
        const offerId = notificationData.offerId
        console.log('opening offer ' + offerId + " from notification");

        // @ts-ignore
        const data = (notification as any).notification.extra ? (notification as any).notification.extra : (notification as any).notification.data;
        console.log('[notification] - opening offer from ' + typeof (notification), notification)
        console.log('[notification] -  opening offer data ' + typeof (notification), data, data.appUrl)
        let finalUrl;
        /*if (notificationData.offerId && notificationData.offerId > 0) {
          //genUrl
          finalUrl = '/client-offer-detail/' + notificationData.offerId
        } else */
        if (data.appUrl) {
          finalUrl = data.appUrl;
          if (finalUrl.indexOf('cdqapp://') == 0) {
            finalUrl = finalUrl.substr(8);
          }
        }
        console.log('[notification open] - will browse to ', finalUrl)
        if (finalUrl && finalUrl != null && finalUrl.length > 0) {
          this.tapOpened = true;
          setTimeout(() => {
            this.ngZone.run(() => {
              if (this.router.url.endsWith(finalUrl)) {
                console.log('route ending with tapped url', this.router.url);
                this.tapOpened = false;
                this.tapUrl = finalUrl;
              } else {
                this.router.navigateByUrl(finalUrl, {
                  state: {
                    type: 'notification',
                  },
                }).then(value => {
                  console.log('offer has been opened', this.tapUrl);
                });
              }
            })
          }, 2000);
        }
      }
    } catch (e) {
      console.log('error while navigating from notif', e)
      this.alertSvc.closeLoader();
    } finally {

    }
  }

  resetTapOpened(){
    this.tapOpened = false;
  }

  forwardNotification(notification:any) {
    const notif: LocalNotificationSchema = {
      id: this.notifId++,
      title: notification.data.title,
      body: notification.data.body,
      extra: {
        notificationId: notification.data.notificationInternalId,
        appUrl: notification.data.appUrl
      },
      channelId: 'cdq-android-notifications',
      actionTypeId: 'offerPush'
    };
    if (notification.data.imageLink) {
      notif.attachments = [{id: 'offerid', url: notification.data.imageLink}];
    }
    if (notification.data.appUrl) {
      if (notification.data.appUrl.indexOf('cdqapp://') == 0) {
        notif.extra.appUrl = notification.data.appUrl.substr(8);
      } else {
        notif.extra.appUrl = notification.data.appUrl;
      }
    }

    //if (this.platform.platforms().includes("android") && this.platform.platforms().includes("mobile") && !this.platform.platforms().includes("mobileweb")) {
    LocalNotifications.schedule({notifications: [notif]});
    //}

  }

  updateDeviceToken() {
    try {
      const me = this;
      console.info('ON CALL, FCM token on service is ', me.fcmToken, this.fcmToken)
      if (this.platform.platforms().includes("mobile") && !this.platform.platforms().includes("mobileweb")) {

        Preferences.get({key: "fcmToken"}).then((options) => {
          let tokenSaved;
          if(options && options.value){
            tokenSaved = options.value;
            console.log('reloaded token', tokenSaved)
          } else {
            tokenSaved = undefined;
          }
          //load firebase id
          try {
            CDQCAPPlugin.firebaseInstallationId().then((res) => {
              console.log('firebase InApp Installation Id: ', res.firebaseInstallationId);
              App.getInfo().then(function (appInfo) {
                Device.getId().then(function (deviceIdHolder) {
                  Device.getInfo().then(function (device) {
                    const finalToken = me.fcmToken ? me.fcmToken : (tokenSaved ? tokenSaved : 'no_token_available');
                    console.log('[notif] token found: ', finalToken)
                    me.securityService.recordNotificationToken({
                      devicePushToken: me.fcmToken ? me.fcmToken : 'no_token_available',
                      version: device.osVersion,
                      os: device.operatingSystem,
                      model: device.model,
                      manufacturer: device.manufacturer,
                      deviceName: device.name,
                      appVersion: appInfo.version,
                      appBuild: appInfo.build,
                      appName: appInfo.name,
                      appId: appInfo.id,
                      deviceUuid: deviceIdHolder.identifier,
                      firebaseInstallId: res.firebaseInstallationId,
                      gitVersion: gitVersion.commit,
                      ionicChanel: me.envService.ionicDeployChannel
                    }).subscribe((r) => {
                      console.log("account updated with fcm token and devic id", r);
                    });
                  })
                });
              });
            })
          }catch (e){
            console.log('unable to load firebase installation id')
          }
        });


      }
    } catch (e) {
      console.log('error while updating device token and id on API', e);
    }

  }

  updateDeviceTokenWhenLoggedId(res:any) {
    try {
      const me = this;
      console.info('ON CALL, FCM token on service is ', me.fcmToken, this.fcmToken);
      if (this.platform.platforms().includes("mobile") && !this.platform.platforms().includes("mobileweb")) {
        Preferences.get({key: "fcmToken"}).then((options) => {
          let tokenSaved;
          if (options && options.value) {
            tokenSaved = options.value;
            console.log('reloaded token', tokenSaved)
          } else {
            tokenSaved = undefined;
          }

          CDQCAPPlugin.firebaseInstallationId().then((res) => {
            console.log('firebase InApp Installation Id: ', res.firebaseInstallationId);

            App.getInfo().then(function (appInfo) {
              Device.getId().then(function (deviceIdHolder) {
                Device.getInfo().then(function (device) {
                  const finalToken = me.fcmToken ? me.fcmToken : (tokenSaved ? tokenSaved : 'no_token_available');
                  console.log('[notif] token found: ', finalToken)
                  me.securityService.recordNotificationTokenWithAccount({
                    devicePushToken: finalToken,
                    version: device.osVersion,
                    os: device.operatingSystem,
                    model: device.model,
                    manufacturer: device.manufacturer,
                    deviceName: device.name,
                    appVersion: appInfo.version,
                    appBuild: appInfo.build,
                    appName: appInfo.name,
                    appId: appInfo.id,
                    deviceUuid: deviceIdHolder.identifier,
                    firebaseInstallId: res.firebaseInstallationId,
                    gitVersion: gitVersion.commit,
                    ionicChanel: me.envService.ionicDeployChannel
                  }).subscribe((r) => {
                    console.log("account updated with fcm token and devic id", r);
                  });
                })
              });
            });
          });
        });
      }
    } catch (e) {
      console.log('error while updating device token and id on API', e);
    }
  }

  listenForMessages() {
    /*this.messagingService.getMessages().subscribe(async (msg: any) => {
        console.log("New message", msg);
        console.log('Body notif', msg.notification.body)
        this.notifContent = msg
        this.notificationService.storeNotification(this.notifContent)
        this.sharedService.sendNotifEvent()
    });*/
  }

  requestPermission() {

  }
}
