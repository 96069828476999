import {Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {Platform, ToastController} from '@ionic/angular';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {SplashScreen} from '@capacitor/splash-screen';
import {Router} from '@angular/router';
import {PublicService, SecurityService} from '@cdq/api';
import {Subscription} from 'rxjs';
import {Network} from '@capacitor/network';
import * as LiveUpdates from '@capacitor/live-updates';
import {EnvService} from './services/environment/env.service';
import {GaService} from './services/googleanalytics/ga.service';
import {KeycloakIonicService} from './services/keycloak/keycloak-ionic.service';
import {AlertService} from "./services/alert/alert.service";
import {CDQCAPPlugin} from "./capacitor/cdq/cdq";
import {gitVersion} from "../environments/git-version";
declare const gtag: Function;
declare let ga: Function;
declare let dT_;

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent implements OnDestroy {
	private popupOpenSubscription: Subscription;
	private popupCloseSubscription: Subscription;
	private initializeSubscription: Subscription;
	private statusChangeSubscription: Subscription;
	private revokeChoiceSubscription: Subscription;
	private noCookieLawSubscription: Subscription;

	showSplash = true;
	userRole: 'VENDOR' | 'CLIENT' | 'OFFLINE' = 'OFFLINE';

	offerId = '209';

	routerHidden = true;
	@ViewChild('splash', {static: false})
	splash: ElementRef;

	isLoggedIn = false;
	notifContent = '';
	cookieEnabled = false;
	appOpened = false;

	constructor(
		public platform: Platform,
		private statusBar: StatusBar,
		public router: Router,
		private securityService: SecurityService,
		private toastCtrl: ToastController,
		private keycloak: KeycloakIonicService,
		private envService: EnvService,
		private ga: GaService,
		private publicService: PublicService,
		private alertService: AlertService
	) {
		this.checkConnection().then((status) => {
			try {
				if (status) {
					this.checkApi().then((value) => {
						if (value) {
							this.initializeApp();
						} else {
							console.log('[app] Api connection error, Maintenance')
						}
					})
				} else {
					this.router.navigateByUrl('/not-found');
				}
			} catch (error) {
				console.log('[app] Get network connection error', error);
			}
		})
	}

	checkConnection(): Promise<any> {
		return new Promise((resolve, reject) => {
			Network.getStatus().then((status) => {
				console.log('[app] Network status', status);
				console.log('[app] Status connected ?', status.connected)
				if (status.connected) {
					resolve(status.connected);
				} else {
					resolve(false);
				}
			}).catch(e => {
				reject(e);
			})
		})
	}

	checkApi(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.publicService.checkConnection().forEach(r => {
				console.log('[app] Checking connection with API', r);
				resolve(r);
			}).catch(e => {
				resolve(e);
			})
		})
	}

	async configureAndUpdate() {
		if (this.platform.platforms().includes('mobile')) {
			if (this.envService.automaticUpdateEnabled) {
        let currentConfig = await LiveUpdates.getConfig();
        console.log('Live Chann: ', currentConfig.channel);
        console.log('Live AppId: ', currentConfig.appId);
        await LiveUpdates.setConfig({
          channel: this.envService.ionicDeployChannel,
          appId: '9be1e97e',
        });
        console.log('Live Chann: ', currentConfig.channel);
        console.log('Live AppId: ', currentConfig.appId);

        const result = await LiveUpdates.sync();
        // If active path has changed, then a new live update was downloaded
        console.log('Live Check Sync Result', result)
        if (result.activeApplicationPathChanged) {
          console.log('reloading app ', result)
          this.runUpdate();
          await LiveUpdates.reload();
        }
			}
		}
	}

	async runUpdate() {
		this.alertService.loadWithMessage('Mise à jour, veuillez patienter ...')
	}

	initializeApp(): void {

		console.log(
			"OLL - starting App in version: " + gitVersion.branch + "( " + gitVersion.commit + ". tag: " + gitVersion.tag + ")"
		);

		// Init Google Analytics
		this.ga.initGa();
		this.platform.ready().then(() => {

			//load firebaseID
			if (this.platform.platforms().includes('mobile') && !this.platform.platforms().includes('mobileweb')) {
				try {
					CDQCAPPlugin.firebaseInstallationId().then((res) => {
						console.log('[app] firebase InApp Installation Id: ', res.firebaseInstallationId);
					})
				} catch (e) {
					console.log('[app] unable to load firebase installation id')
				}
			}

			Network.addListener('networkStatusChange', (status) => {
				console.log('[app] Network status changed', status);
				if (!status.connected) {
					this.toastCtrl.create({
						position: 'top',
						header: 'Erreur',
						message: 'La communication avec le service est impossible. Vérifiez votre connexion.',
						color: 'danger',
						duration: 3000,
						buttons: [
							{
								side: 'end',
								text: 'OK',
								role: 'cancel',
								handler: () => {
									console.log('[app] Cancel');
								}
							}
						]
					}).then((toast) => {
						toast.present();
					});
				} else {
					console.log('[app] Connection is OK');
				}
			});


			// if (this.platform.platforms().includes('desktop') || this.platform.platforms().includes('mobileweb')) {
			// 	if (!this.ccService.hasAnswered()) {
			// 		this.ccService.open();
			// 	}
			// }

			// this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
			// 	// you can use this.ccService.getConfig() to do stuff...
			// });

			// this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
			// 	// you can use this.ccService.getConfig() to do stuff...
			// });

			// this.initializeSubscription = this.ccService.initialize$.subscribe((event: NgcInitializeEvent) => {
			// 	// you can use this.ccService.getConfig() to do stuff...
			// });

			// this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
			// 	// you can use this.ccService.getConfig() to do stuff...
			// });

			// this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(() => {
			// 	// you can use this.ccService.getConfig() to do stuff...
			// });

			// this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => {
			// 	// you can use this.ccService.getConfig() to do stuff...
			// });

			this.keycloak.isLoggedIn().then((connected) => {
				try {
					if (connected) {
						this.isLoggedIn = true;
						this.securityService.apiV1SecurityLoadGet().forEach((r) => {
							this.userRole = r.result.userType;
							if (this.platform.platforms().includes('mobile')) {
								this.statusBar.styleDefault();
								SplashScreen.hide({
									fadeOutDuration: 3000
								});
							} else {
							}
						});
					} else {
						if (this.platform.platforms().includes('mobile')) {
							this.statusBar.styleDefault();
							SplashScreen.hide({
								fadeOutDuration: 3000
							});
						}
					}
				} catch (e) {
					console.log('[app] error while initlizing app', e);
				}
			});

			//google analytics handle
			// this.router.events.subscribe((event) => {
			// 	try {
			// 		if (event instanceof NavigationEnd) {
			// 			ga('set', 'page', event.urlAfterRedirects);
			// 			ga('send', 'pageview');
			// 		}
			// 	} catch (e) {
			// 		console.log('error while setting analytics events', e);
			// 	}
			// });

			console.log('[app] deployment config update ', this.envService.ionicDeployChannel);
			this.configureAndUpdate();
		});
	}

	ngOnDestroy(): void {
		this.popupOpenSubscription.unsubscribe();
		this.popupCloseSubscription.unsubscribe();
		this.initializeSubscription.unsubscribe();
		this.statusChangeSubscription.unsubscribe();
		this.revokeChoiceSubscription.unsubscribe();
		this.noCookieLawSubscription.unsubscribe();
	}
}
