import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Configuration } from '@cdq/api';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Device } from '@ionic-native/device/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ChartsModule } from 'ng2-charts';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { AgmCoreModule } from '@agm/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import * as Sentry from '@sentry/angular';
import { CommonModule } from '@angular/common';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {EnvService} from './services/environment/env.service';
import {EnvServiceProvider} from './services/environment/env.service.provider';
import {KeycloakIonicService} from './services/keycloak/keycloak-ionic.service';
import {initializer} from './services/keycloak/keycloak.initializer';
import {KeycloakInterceptorService} from './services/keycloak/keycloak.interceptor.service';
import {NavigationComponent} from './components/navigation/navigation.component';
import {SwiperModule} from 'swiper/angular';


@NgModule({
    declarations: [
        AppComponent,
        NavigationComponent,
    ],
    imports: [
        SwiperModule,
        CommonModule,
        SuperTabsModule.forRoot(),
        ChartsModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ServiceWorkerModule.register('combined-sw.js', {
            enabled: environment.production,
        }),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAAr8z3OjnqnW5Wqp-r4eBWizbu6eayxk8',
            libraries: ['places'],
        }),
        BrowserAnimationsModule,
        MatDatepickerModule,
        MatInputModule,
        Ng2SearchPipeModule,
    ],
    providers: [
        EnvServiceProvider,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                logErrors: true,
                showDialog: false,
            }),
        },
        Device,
        BarcodeScanner,
        StatusBar,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: KeycloakInterceptorService,
            multi: true,
        },
        {
            provide: KeycloakIonicService,
        },
        {
            provide: Configuration,
            useFactory: (envService: EnvService) => {
                console.log('[app module] apiUrl : ', envService.apiUrl);
                const configBase = {
                    withCredentials: true,
                    basePath: envService.apiUrl,
                };
                return new Configuration(configBase);
            },
            deps: [EnvService],
            multi: false,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            deps: [EnvService, KeycloakIonicService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
